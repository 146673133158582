import { ButtonIcon } from '@/components/common/button/constants'

const QUESTION_MARK =
  "flex flex-row-reverse items-center pr-10 md:pr-10 before:translate-x-[28px] before:absolute before:b-0 before:t-0 before:r-0 before:inline-flex before:bg-contain before:bg-no-repeat before:bg-center before:icon-question-mark before:content-[''] before:h-5 before:w-5"
const ARROW_BASE =
  "flex flex-row-reverse items-center pr-10 md:pr-10 before:absolute before:b-0 before:t-0 before:inline-flex before:bg-contain before:bg-no-repeat before:bg-center before:icon-button-chevron_right before:content-[''] before:w-3 md:pl-5 lg:pl-3 pl-5"
const ARROW_RIGHT = `${ARROW_BASE} before:translate-x-[24px]`
const ARROW_RIGHT_SLIM = `${ARROW_BASE} before:translate-x-[20px] lg:pr-7`
const ARROW_DOWN_BASE =
  "flex flex-row-reverse items-center pr-6 custom-breakpoint-800:pl-[15px] lg:pr-10 lg:before:translate-x-[26px] before:translate-x-[10.5px] before:absolute before:b-0 before:t-0 before:inline-flex before:bg-contain before:bg-no-repeat before:bg-center before:content-[''] before:h-3.5 before:w-3.5"
const ARROW_DOWN = `${ARROW_DOWN_BASE} before:icon-button-chevron_down`
const ARROW_DOWN_DARK_SEARCH_RESULT = `${ARROW_DOWN_BASE} before:icon-button-chevron_down_dark`
const ARROW_DOWN_DARK_SEARCH_RESULT_WITHOUT_TEXT_ON_MOBILE = `${ARROW_DOWN_DARK_SEARCH_RESULT} before:translate-x-[12px]`
const ARROW_DOWN_DARK_SEARCH_RESULT_WITH_TEXT_ON_MOBILE = `${ARROW_DOWN_DARK_SEARCH_RESULT} before:translate-x-[26px] pr-[38px]`
export const ICONS = {
  [ButtonIcon.ARROW_RIGHT]: ARROW_RIGHT,
  [ButtonIcon.ARROW_DOWN]: ARROW_DOWN,
  [ButtonIcon.ARROW_DOWN_DARK_SEARCH_RESULT_WITHOUT_TEXT_ON_MOBILE]:
    ARROW_DOWN_DARK_SEARCH_RESULT_WITHOUT_TEXT_ON_MOBILE,
  [ButtonIcon.ARROW_DOWN_DARK_SEARCH_RESULT_WITH_TEXT_ON_MOBILE]:
    ARROW_DOWN_DARK_SEARCH_RESULT_WITH_TEXT_ON_MOBILE,
  [ButtonIcon.QUESTION_MARK]: QUESTION_MARK,
  [ButtonIcon.ARROW_RIGHT_SLIM]: ARROW_RIGHT_SLIM,
}
