'use client'

import React, { ReactElement, useContext } from 'react'
import { ConfigContext } from '@/components/common/ConfigContext'
import { renderXTimes } from '@/lib/utils/utils'

function formatRating(rating: number, locale: string): string {
  return new Intl.NumberFormat(locale, { maximumFractionDigits: 1 }).format(rating)
}

const TOTAL_NUMBER_OF_STARS = 5

function FullStar(): ReactElement {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clip0_1499_39550">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip0_1499_39550)">
        <path
          d="M5.91861 1.25206C6.35438 0.344797 7.64623 0.344797 8.082 1.25206L9.1065 3.38505C9.28781 3.76253 9.65279 4.01819 10.0695 4.05961L12.2469 4.27605C13.2051 4.37128 13.6665 5.49936 13.0498 6.23877L11.2561 8.38941C11.0191 8.67349 10.9265 9.05096 11.0049 9.41246L11.5046 11.7155C11.7118 12.6704 10.7518 13.4594 9.85505 13.0711L7.47712 12.0415C7.17289 11.9097 6.82772 11.9097 6.52348 12.0415L4.14549 13.0711C3.24877 13.4594 2.28876 12.6704 2.49597 11.7155L2.98857 9.44525C3.07101 9.06532 2.96428 8.66898 2.7022 8.38183L0.977472 6.49214C0.338337 5.79188 0.725468 4.66188 1.65974 4.50066L3.9924 4.09813C4.37496 4.03211 4.70195 3.7851 4.87004 3.43516L5.91861 1.25206Z"
          className="fill-primary"
        />
      </g>
    </svg>
  )
}

function EmptyStar(): ReactElement {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clip0_1499_39552">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip0_1499_39552)">
        <path
          d="M7.63129 1.46854L8.6558 3.60153C8.91265 4.1363 9.42971 4.49848 10.0201 4.55716L12.1975 4.7736C12.7564 4.82915 13.0256 5.48719 12.6658 5.91852L10.8721 8.06916C10.5365 8.4716 10.4052 9.00635 10.5163 9.51848L11.016 11.8215C11.1368 12.3785 10.5768 12.8388 10.0537 12.6123L7.6758 11.5826C7.24481 11.396 6.75581 11.396 6.32481 11.5826L3.94682 12.6123C3.42373 12.8388 2.86372 12.3785 2.9846 11.8215L3.4772 9.55128C3.59399 9.01303 3.44279 8.45157 3.0715 8.04476L1.34678 6.15508C0.973949 5.74659 1.19977 5.08743 1.74477 4.99338L4.07743 4.59085C4.61939 4.49732 5.08263 4.14739 5.32074 3.65164L6.36932 1.46854C6.62352 0.939303 7.37709 0.939303 7.63129 1.46854Z"
          fill="#E8E8E8"
          stroke="#E0E0E0"
        />
      </g>
    </svg>
  )
}

function HalfFullStar(): ReactElement {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clip0_1499_39554">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip0_1499_39554)">
        <path
          d="M7.63129 1.46854L8.6558 3.60153C8.91265 4.1363 9.42971 4.49848 10.0201 4.55716L12.1975 4.7736C12.7564 4.82915 13.0256 5.48719 12.6658 5.91852L10.8721 8.06916C10.5365 8.4716 10.4052 9.00635 10.5163 9.51848L11.016 11.8215C11.1368 12.3785 10.5768 12.8388 10.0537 12.6123L7.6758 11.5826C7.24481 11.396 6.75581 11.396 6.32481 11.5826L3.94682 12.6123C3.42373 12.8388 2.86372 12.3785 2.9846 11.8215L3.4772 9.55128C3.59399 9.01303 3.44279 8.45157 3.0715 8.04476L1.34678 6.15508C0.973949 5.74659 1.19977 5.08743 1.74477 4.99338L4.07743 4.59085C4.61939 4.49732 5.08263 4.14739 5.32074 3.65164L6.36932 1.46854C6.62352 0.939303 7.37709 0.939303 7.63129 1.46854Z"
          fill="#E8E8E8"
          stroke="#E0E0E0"
        />
        <mask id="mask0_1499_39554" maskUnits="userSpaceOnUse" x="0" y="0" width="7" height="14">
          <rect width="7" height="14" fill="#E8E8E8" />
        </mask>
        <g mask="url(#mask0_1499_39554)">
          <path
            d="M5.91861 1.25206C6.35438 0.344797 7.64623 0.344797 8.082 1.25206L9.1065 3.38505C9.28781 3.76253 9.65279 4.01819 10.0695 4.05961L12.2469 4.27605C13.2051 4.37128 13.6665 5.49936 13.0498 6.23877L11.2561 8.38941C11.0191 8.67349 10.9265 9.05096 11.0049 9.41246L11.5046 11.7155C11.7118 12.6704 10.7518 13.4594 9.85505 13.0711L7.47712 12.0415C7.17289 11.9097 6.82772 11.9097 6.52348 12.0415L4.14549 13.0711C3.24877 13.4594 2.28876 12.6704 2.49597 11.7155L2.98857 9.44525C3.07101 9.06532 2.96428 8.66898 2.7022 8.38183L0.977472 6.49214C0.338337 5.79188 0.725468 4.66188 1.65974 4.50066L3.9924 4.09813C4.37496 4.03211 4.70195 3.7851 4.87004 3.43516L5.91861 1.25206Z"
            className="fill-primary"
          />
        </g>
      </g>
    </svg>
  )
}

export function StarsWithoutRating(props: { rating: number }): ReactElement {
  const numberFullStars = Math.floor(props.rating)
  const numberHalfStars = props.rating % 1 >= 0.5 ? 1 : 0
  const numberEmptyStars = TOTAL_NUMBER_OF_STARS - numberFullStars - numberHalfStars
  return (
    <>
      {renderXTimes(numberFullStars, FullStar)}
      {renderXTimes(numberHalfStars, HalfFullStar)}
      {renderXTimes(numberEmptyStars, EmptyStar)}
    </>
  )
}

export default function Stars(props: { rating: number | undefined }): ReactElement | null {
  const config = useContext(ConfigContext)!

  if (props.rating === undefined) {
    return null
  }

  return (
    <div className="flex items-center">
      <StarsWithoutRating rating={props.rating} />
      <div className="ml-1.5 font-medium text-black-74">
        <div className="inline font-semibold">{formatRating(props.rating, config.locale)}</div>/
        {TOTAL_NUMBER_OF_STARS}
      </div>
    </div>
  )
}
