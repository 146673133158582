import z, { array, boolean, object, string } from 'zod'
import { isValidIBAN, electronicFormatIBAN } from 'ibantools'

export enum FitReisenPaymentCode {
  Invoice = 'RG',
  DirectDebit = 'LS',
  CreditCard = 'CC',
  Paypal = 'PP',
  Sofort = 'SU',
  Przelewy24 = 'PR',
  Blik = 'BK',
  Ideal = 'ID',
  Eps = 'EP',
  ApplePay = 'AP',
  Twint = 'TW',
  Klarna = 'KL',
  Bancontact = 'BC',
}

export enum BookingRequestType {
  Booking = 1,
  Request = 2,
}

export enum Gender {
  Female = 1,
  Male = 2,
}

export interface BookingRequestExtraSelection {
  ExtraCode: string
  Quantity: number
}

export interface BookingRequestJson {
  Type: BookingRequestType
  ProgramCode: string
  RoomCode: string
  MealPlanCode: number
  Beds: number
  Duration: number
  DateFrom: string
  Price: number
}

interface BookingRequestCustomer {
  Gender: Gender
  FirstName: string
  LastName: string
  Address: string
  PostalCode: string
  City: string
  CountryCode: string
  Email: string
  Phone: string
  Iban: string | null
  AccountHolder: string | null
}

interface BookingRequestTraveller {
  Gender: Gender
  FirstName: string
  LastName: string
}

export interface BookingRequestSelection {
  RoomCode: string
  MealPlanCode: number
  Extras: BookingRequestExtraSelection[]
  InsuranceCode: string | null
  PaymentMethodCode: string | null
  FlexBooking: boolean
  Reservation: boolean
  VoucherCode: string | undefined
  Customer: BookingRequestCustomer
  Travellers: BookingRequestTraveller[]
  MessageToHotel: string
  Comment: string
  RapFlex?: boolean | undefined
  RapFlexMinDate?: string | undefined
  RapFlexMaxDate?: string | undefined
}

export enum BookingResponseStatus {
  Ok = 1,
  Error = 2,
  Warning = 3,
}

interface BookingPrice {
  Code: string
  Price: string
}

export interface PaymentFee {
  Code: FitReisenPaymentCode
  Fee?: string
}

interface BookingPrices {
  InsurancePrices: BookingPrice[]
  PaymentPrices: PaymentFee[]
  FlexBookingPrice: string
}

export interface BookingListJson {
  Code: string
  Name: string
  Description: string
  Price: string
}

interface BookingVoucher {
  Code: string
  IsValid: boolean
  Message: string
  Price: string
}

interface BookingDetail {
  Label: string
}

interface BookingSummary {
  Label: string
  Price: string
}

interface BookingData {
  ProgramName: string
  ProgramDuration: string
  ProgramExtension: string
  ProgramDescription: string
  HotelName: string
  HotelDestination: string
  HotelImages: string[]
  InsuranceAllowed: boolean
  ReservationAllowed: boolean
  FlexBookingAllowed: boolean
  RequestWithFlightAllowed: boolean
  Rooms: Room[]
  Extras: Extra[]
  MealPlans: BookingListJson[]
}

export interface RoomData {
  [key: string]: string | string[] | [{ Icon: string; Text: string }]
  Code: string
  Description: string
  Features: string[]
  Images: string[]
  Name: string
  PopularFeatures: [{ Icon: string; Text: string }]
}

interface Room {
  Code: string
  Price: string
  Data: RoomData
}

export interface Extra {
  Code: string
  Price: string
  Data: ExtraData
}

export interface ExtraData {
  [key: string]: string
  Code: string
  Description: string
  Image: string
  Name: string
}

export interface BookingResponseJson extends BaseBookingResponse {
  Status: BookingResponseStatus
  StatusText: string
  BookingData: BookingData
  Voucher: BookingVoucher
}

export interface BaseBookingResponse {
  Status: BookingResponseStatus
  StatusText: string
  BookingDetailList: BookingDetail[]
  BookingPrices: BookingPrices
  BookingSummaryList: BookingSummary[]
  PaymentMessage: string
}

export interface SafeBookingResponse extends BaseBookingResponse {
  OrderId: string
  CustomerNumber: string
}

function getTravelerSchema(t?: (string) => string) {
  const translate = getTranslate(t)

  return object({
    FirstName: string().min(1, translate('bookingFormValidationMissingFirstName')),
    LastName: string().min(1, translate('bookingFormValidationMissingLastName')),
    Gender: string({
      required_error: translate('bookingFormValidationMissingGender'),
      invalid_type_error: translate('bookingFormValidationMissingGender'),
    }).refine(
      (value) => value === '1' || value === '2',
      { message: translate('bookingFormValidationMissingGender') }, // Custom error message for mismatch
    ),
  })
}

function getCustomerSchema(t?: (string) => string) {
  const translate = getTranslate(t)

  return object({
    Gender: string({
      required_error: translate('bookingFormValidationMissingGender'),
      invalid_type_error: translate('bookingFormValidationMissingGender'),
    }).refine(
      (value) => value === '1' || value === '2',
      { message: translate('bookingFormValidationMissingGender') }, // Custom error message for mismatch
    ),
    FirstName: string().min(1, translate('bookingFormValidationMissingFirstName')),
    LastName: string().min(1, translate('bookingFormValidationMissingLastName')),
    Address: string().min(1, translate('bookingFormValidationMissingAddress')),
    PostalCode: string().min(1, translate('bookingFormValidationMissingPostalCode')),
    City: string().min(1, translate('bookingFormValidationMissingCity')),
    CountryCode: string().length(2, translate('bookingFormValidationMissingCountry')),
    Email: string().email(translate('bookingFormValidationInvalidEmail')),
    Phone: string().min(1, translate('bookingFormValidationMissingPhone')),
    Iban: string().nullable(),
    AccountHolder: string().nullable(),
  })
}

export function getUnrefinedFormSchema(t?: (string) => string) {
  const translate = getTranslate(t)

  return object({
    Customer: getCustomerSchema(t),
    Travelers: array(getTravelerSchema(t)),
    TermsAndConditionsAccepted: boolean().refine((value) => value, {
      message: translate('bookingFormValidationTACNotAccepted'),
    }),
    Insurance: string().min(1, translate('bookingFormValidationMissingInsurance')),
    FlexTarifAccepted: boolean().optional(),
    Reservation: boolean().optional(),
    FlightOption: string().optional(),
    VoucherCode: string().optional(),
    Comment: string().optional(),
  })
}

function getUnrefinedFormSchemaRequest(t?: (string) => string) {
  return getUnrefinedFormSchema(t).extend({
    RapFlex: boolean().nullable().optional(),
    RapFlexMinDate: string().nullable().optional(),
    RapFlexMaxDate: string().nullable().optional(),
    FlightAirport: string().nullable().optional(),
    FlightOption: string().nullable().optional(),
  })
}

function getUnrefinedFormSchemaBooking(t?: (string) => string) {
  // const translate = getTranslate(t)

  return getUnrefinedFormSchema(t).extend({
    PaymentMethod: string().optional().nullable(),
  })
}

const unrefinedFormSchemaBooking = getUnrefinedFormSchemaBooking()

type UnrefinedBookingFormData = z.infer<typeof unrefinedFormSchemaBooking>

function refineDirectDebitCustomerProps(
  prop: 'Iban' | 'AccountHolder',
  message: string,
): [(data: UnrefinedBookingFormData) => boolean, { message: string; path?: string[] }] {
  return [
    (data) => {
      return !(
        data.PaymentMethod === 'LS' &&
        (!data.Customer[prop] || data.Customer[prop]?.trim() === '')
      )
    },
    {
      path: ['Customer', prop],
      message,
    },
  ]
}

function getTranslate(t?: (string) => string): (key: string) => string {
  function translate(key: string) {
    return t ? t(key) : key
  }

  return translate
}

export function getFormSchemaRequest(t?: (string) => string) {
  const translate = getTranslate(t)

  return getUnrefinedFormSchemaRequest(t)
    .refine(
      (data) => {
        if (data.RapFlex) {
          return data.RapFlexMinDate
        }
        return true
      },
      {
        message: translate('bookingFormValidationDateMissing'),
        path: ['RapFlexMinDate'],
      },
    )
    .refine(
      (data) => {
        if (data.RapFlex) {
          return data.RapFlexMaxDate
        }
        return true
      },
      {
        message: translate('bookingFormValidationDateMissing'),
        path: ['RapFlexMaxDate'],
      },
    )
}

export function getFormSchemaBooking(paymentRequired: boolean, t?: (string) => string) {
  const translate = getTranslate(t)

  const formSchemaBooking = paymentRequired
    ? getUnrefinedFormSchemaBooking(t).extend({
        PaymentMethod: string().min(1, translate('bookingFormValidationMissingPaymentMethod')),
      })
    : getUnrefinedFormSchemaBooking(t)

  return formSchemaBooking
    .refine(
      ...refineDirectDebitCustomerProps('Iban', translate('bookingFormValidationMissingIban')),
    )
    .refine(
      ...refineDirectDebitCustomerProps(
        'AccountHolder',
        translate('bookingFormValidationMissingAccountHolder'),
      ),
    )
    .refine(
      (data) => {
        if (data.PaymentMethod !== 'LS' || !data.Customer.Iban) {
          return true
        }

        const iban = electronicFormatIBAN(data.Customer.Iban)
        return iban && isValidIBAN(iban)
      },
      {
        path: ['Customer', 'Iban'],
        message: translate('bookingFormValidationInvalidIban'),
      },
    )
}

const formSchemaBooking = getFormSchemaBooking(false)
const formSchemaRequest = getFormSchemaRequest()

export type BookingFormDataBooking = z.infer<typeof formSchemaBooking>
export type BookingFormDataRequest = z.infer<typeof formSchemaRequest>
export type BookingFormData = BookingFormDataBooking | BookingFormDataRequest
