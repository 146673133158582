'use client'

import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  MouseEvent,
  ReactElement,
  ReactNode,
} from 'react'
import classNames from 'classnames'
import { ButtonIcon } from '@/components/common/button/constants'
import { BASE_BUTTON_STYLES, STANDARD_BUTTON_TEXT_SIZE } from '@/components/common/button/styles'
import { ICONS } from '@/components/common/button/icons'
import { ButtonType } from '@/components/common/button/ButtonType'
import { twMerge } from 'tailwind-merge'

interface ButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  // textSize?: string
  smaller?: boolean
  onClick?: (e: MouseEvent) => void
  children?: ReactNode
  className?: string
  icon?: ButtonIcon
  buttonType?: ButtonType
  // padding?: string
}

export default function Button({ buttonType: buttonType, ...props }: ButtonProps): ReactElement {
  const className = classNames(
    // props.padding,
    BASE_BUTTON_STYLES,
    'px-4 py-1.5',
    STANDARD_BUTTON_TEXT_SIZE,
    props.icon ? ICONS[props.icon] : undefined,
    {
      'min-h-[44px]': !props.smaller,
      'min-h-[32px] lg:h-[20px]': props.smaller,
      'bg-primary': buttonType !== ButtonType.SECONDARY && !props.disabled,
      'bg-primary-lightened': buttonType !== ButtonType.SECONDARY && props.disabled,
      'lg:px-3': buttonType === ButtonType.SECONDARY,
      'border bg-transparent text-stone-600': buttonType === ButtonType.SECONDARY,
      'before:h-2.5':
        props.icon &&
        [ButtonIcon.ARROW_RIGHT, ButtonIcon.ARROW_RIGHT_SLIM].includes(props.icon) &&
        props.smaller,
      'before:h-3':
        props.icon &&
        [ButtonIcon.ARROW_RIGHT, ButtonIcon.ARROW_RIGHT_SLIM].includes(props.icon) &&
        !props.smaller,
      'lg:pr-10': ButtonIcon.ARROW_RIGHT === props.icon && props.smaller,
      'lg:pr-12': ButtonIcon.ARROW_RIGHT === props.icon && !props.smaller,
    },
  )

  return (
    <button {...props} onClick={props.onClick} className={twMerge(className, props.className)}>
      {props.children}
    </button>
  )
}
