'use client'

import React, { MouseEvent, ReactElement, ReactNode, useEffect } from 'react'
import classNames from 'classnames'
import XmarkIcon from '@/icons/font-awesome/sharp-light/xmark.svg'
import { lockScrollableBody, unlockScrollableBody } from '@/lib/utils/utils'
import { twMerge } from 'tailwind-merge'

interface ModalProps {
  isOpen: boolean
  close: () => void
  children: ReactNode
  title: string
  className?: string
  narrow?: boolean
}

export function ModalNew(props: ModalProps): ReactElement {
  useEffect(() => {
    if (props.isOpen) {
      lockScrollableBody()
    } else {
      unlockScrollableBody()
    }
    return () => unlockScrollableBody()
  }, [props.isOpen])

  function close(e: MouseEvent): void {
    unlockScrollableBody()
    props.close()
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <div
      className={twMerge(
        classNames('relative inline', {
          hidden: !props.isOpen,
        }),
        props.className,
      )}
      onClick={close}
    >
      <div className="fixed inset-0 z-40 bg-gray-400 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div
          className={classNames(
            'flex h-screen items-stretch justify-center text-center sm:items-center',
          )}
        >
          <div
            className={twMerge(
              classNames(
                'relative flex h-full w-screen flex-col overflow-hidden bg-white text-left shadow-xl sm:max-h-[80vh] sm:rounded-md',
                {
                  'sm:h-auto sm:max-w-xl': props.narrow,
                  'w-sm:h-[80vh] sm:h-auto sm:min-h-[300px] sm:w-[70vw] sm:max-w-3xl':
                    !props.narrow,
                },
                props.className,
              ),
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex items-center justify-between border-b-2 border-stone-100 align-top">
              {props.title && (
                <div className="p-4 text-2xl font-bold text-stone-900">{props.title}</div>
              )}
              <div className="px-5 py-3 text-xl font-semibold"></div>
              <button className="cursor-pointer fill-stone-900 p-2" onClick={close}>
                <XmarkIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="overflow-y-auto">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export interface ModalNewWithHeadingProps {
  title: string
  content: string
  isOpen: boolean
  close: () => void
  narrow?: boolean
}

export function ModelNewWithHeading(props: ModalNewWithHeadingProps): ReactElement {
  return (
    <ModalNew close={props.close} isOpen={props.isOpen} title="" narrow={props.narrow}>
      <div className="p-5">
        <h4 className="mb-2 mt-0 text-2xl font-bold text-stone-900">{props.title}</h4>
        <p
          className="mt-0 text-sm font-normal text-stone-800"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </ModalNew>
  )
}
