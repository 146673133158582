import { Currency, SearchItem } from '@/types/Page'
import { Domain } from '../domain-config/domain-config'
import _flatten from 'lodash/flatten'
import {
  HotelImage,
  HotelRatingsData,
  ProgramsUpdate,
  TrackHotelImpressionLandingPageParams,
  TrackHotelImpressionSearchParams,
  TrackHotelPageViewParams,
  TrackLikeParams,
  TrackPageViewParams,
  WizardAvailability,
  WizardOffers,
} from '@/types/Misc'
import Cookies from 'js-cookie'
import {
  getTestVariantsCookieValue,
  getTestVariantsString,
  TEST_VARIANTS_COOKIE,
} from './ab-tests/test-variants'
import { fetchWithAbort } from '@/lib/utils/utils'
import {
  BookingRequestJson,
  BookingRequestSelection,
  BookingRequestType,
  BookingResponseJson,
  SafeBookingResponse,
} from '@/types/Booking'

export const API_URL = process.env.API_URL || 'https://api.fitreisen.de/v3/'
export const HOTEL_SEARCH_PAGE_SIZE = 10
const REDIRECT_STATUS_CODES = [301, 302, 303, 307, 308]
let isBookingInProgress = false

export interface SearchResultOffer {
  ProgramCode: string
  ProgramName: string
  ProgramFeatures: string
  ProgramText: string
  Price: string
  PriceOriginal: string
  Url: string
}

type PopularityEntry = [string, string]

export interface SearchPageSearchResultItem {
  LikesCount: number
  Description: string
  Destinations: string[]
  Discount: string
  HotelCategoryStars: number
  HotelCategoryText: string
  SpecialCategory: string
  Annotation: string
  HotelCode: string
  HotelName: string
  Images: string[]
  RatingText: string
  RatingValue: number
  SearchResultOffers: SearchResultOffer[]
  IsKmwHotel: boolean
  Url: string
  UniqueSellingPoints: string[]
  Popularities: PopularityEntry[] | undefined
  TopBar: string
}

export interface Quantity {
  NumberOfOffers: number
  NumberOfResults: number
}

export interface SearchResult extends Quantity {
  SearchResultItems: SearchPageSearchResultItem[]
  MapItems: ExtendedHotelInfo[]
}

export interface SearchPageSearchResult extends Quantity {
  SearchResult: SearchResult
  BackfillResult: SearchResult
  Currency: Currency
  Message: string
  loadMore: () => void
}

interface Label {
  Type: string
  Value: string
  Label: string
}

interface OriginalLabelSearchResult {
  search: {
    destinations?: Label[]
    topics?: Label[]
    topicTypes?: Label[]
  }
}

interface LabelSearchResult {
  search: {
    destinations: SearchItem[]
    topics: SearchItem[]
  }
}

export interface ExtendedHotelInfo extends BasicHotelInfo {
  PriceString: number
  SpecialCategory?: string
  Annotation: string
  PriceLine1: string
  PriceLine2: string
  PriceOriginal: number
  RatingValue: number
  Price: number
  Discount: string
}

export interface BasicHotelInfo {
  HotelCode: string
  HotelName: string
  Latitude: number
  Longitude: number
  Images: string[]
  Url: string
  Destinations: string[]
  HotelCategoryStars: number
  HotelCategoryText: string
  RatingText: string
  RatingValue: number
}

function downcaseKeys(o: Label): SearchItem {
  return Object.fromEntries(
    Object.entries(o).map(([key, val]) => [key.toLowerCase(), val]),
  ) as SearchItem
}

function adaptFetchLabelsResult(fetchResult: OriginalLabelSearchResult): LabelSearchResult {
  return {
    search: {
      destinations: fetchResult.search.destinations?.map((o) => downcaseKeys(o)) || [],
      topics:
        _flatten([fetchResult.search.topicTypes || [], fetchResult.search.topics || []]).map((o) =>
          downcaseKeys(o),
        ) || [],
    },
  }
}

export async function fetchHotelPrograms(
  hotelCode: string,
  domain: Domain,
  params: URLSearchParams,
  currency: Currency,
): Promise<ProgramsUpdate> {
  params.set('h', hotelCode)
  params.set('domain', domain)
  params.set('currency', currency)

  return (await fetch(`${API_URL}hotels/programs/?` + params.toString()).then((r) => {
    if (!r.ok) {
      return r.text().then((text) => {
        console.error('Error fetching hotel programs', r.status, text)
        return { programs: [], additionalPrograms: [] }
      })
    }

    return r.json()
  })) as ProgramsUpdate
}

export async function fetchWizardOffers(args: {
  programCode: string
  beds: string
  roomCode: string
  duration: string
  mealPlanCode: string
  currency: Currency
  dateFrom?: string
  domain: Domain
}): Promise<WizardOffers> {
  return (await fetchWithAbort(
    `${API_URL}booking/wizard-offers/?` + new URLSearchParams(args).toString(),
  ).then((r) => r.json())) as WizardOffers
}

export async function fetchWizardAvailability(args: {
  currency: Currency
  price: string
  offerId: string
  domain: Domain
}): Promise<WizardAvailability> {
  return (await fetchWithAbort(
    `${API_URL}booking/wizard-availability/?` + new URLSearchParams(args).toString(),
  ).then((r) => r.json())) as WizardAvailability
}

export async function fetchLabels(
  searchParams: URLSearchParams,
  domain: Domain,
): Promise<LabelSearchResult> {
  searchParams.append('domain', domain)
  const result = (await fetch(
    `${API_URL}search/labels?` + searchParams.toString().replace(/undefined/g, ''),
  ).then((r) => r.json())) as OriginalLabelSearchResult

  return adaptFetchLabelsResult(result)
}

export async function fetchAllHotels(domain: Domain): Promise<BasicHotelInfo[]> {
  return (await fetch(`${API_URL}search/map/?` + new URLSearchParams({ domain }).toString()).then(
    (r) => r.json(),
  )) as BasicHotelInfo[]
}

export async function fetchHotelRatingsData(
  domain: Domain,
  hotelCode: string,
  ratingsOrder: string,
  travellerType: string,
  page: number,
): Promise<HotelRatingsData> {
  return (await fetch(
    `${API_URL}hotels/ratings?h=${hotelCode}&page=${page}&ratingsOrder=${ratingsOrder}&travellerType=${travellerType}&domain=${domain}`,
  ).then((r) => r.json())) as HotelRatingsData
}

export async function fetchSearchResult(
  domain: Domain,
  currency: Currency,
  page: number,
  params: Record<string, string>,
  results?: number | 'all',
): Promise<SearchPageSearchResult> {
  const fetchResult = await fetchWithAbort(
    `${API_URL}search?` +
      new URLSearchParams({
        domain,
        currency,
        page: page.toString(),
        ...params,
        results: results?.toString() || HOTEL_SEARCH_PAGE_SIZE.toString(),
      })
        .toString()
        .replace(/undefined/g, ''),
  ).then((r) =>
    (r.ok ? r.json() : Promise.resolve({ ...SEARCH_FALLBACK_RESULT })).then(
      (r: SearchPageSearchResult) => {
        r.BackfillResult = r.BackfillResult || SEARCH_FALLBACK_RESULT.BackfillResult
        return r
      },
    ),
  )

  return fetchResult
}

export async function getBooking(
  params: BookingRequestJson & { domain: Domain; currency: Currency },
) {
  const urlParams = new URLSearchParams({ domain: params.domain, currency: params.currency })
  // https://api.fitreisen.de/v2/booking/get/?domain=fitreisen.de&currency=EUR
  return (await fetch(`${API_URL}booking/get/?` + urlParams.toString(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', // Specify the content type as JSON
    },
    body: JSON.stringify(params),
  }).then((r) => {
    if (REDIRECT_STATUS_CODES.includes(r.status)) {
      window.location.href = r.headers.get('Location') || ''
    } else if (!r.ok) {
      // window.location.href = '/search'
    } else {
      return r.json()
    }
  })) as BookingResponseJson
}

async function executeBooking(
  initialBookingData: BookingRequestJson,
  selection: BookingRequestSelection,
  domain: Domain,
  currency: Currency,
  action: 'update' | 'save',
) {
  const urlParams = new URLSearchParams({ domain, currency })
  return await fetch(`${API_URL}booking/${action}/?` + urlParams.toString(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...initialBookingData, Selection: selection }),
  })
    .then((r) => {
      return r.json() as unknown
    })
    .finally(() => {
      isBookingInProgress = false
    })
}

export function saveBooking(
  initialBookingData: BookingRequestJson,
  selection: BookingRequestSelection,
  domain: Domain,
  currency: Currency,
) {
  if (isBookingInProgress) {
    throw new Error('A booking request is already in progress')
  }
  if (initialBookingData.Type !== BookingRequestType.Booking) {
    throw new Error('Only booking requests for now')
  }

  isBookingInProgress = true

  return executeBooking(
    initialBookingData,
    selection,
    domain,
    currency,
    'save',
  ) as Promise<SafeBookingResponse>
}

export function updateBooking(
  initialBookingData: BookingRequestJson,
  selection: BookingRequestSelection,
  domain: Domain,
  currency: Currency,
) {
  if (isBookingInProgress) {
    throw new Error('A booking request is already in progress')
  }

  return executeBooking(initialBookingData, selection, domain, currency, 'update') as Promise<
    Partial<BookingResponseJson>
  >
}

export async function fetchHotelImages(domain: string, hotel: string): Promise<HotelImage[]> {
  const params = new URLSearchParams({ domain, h: hotel })

  return (await fetch(`${API_URL}hotels/images/?${params.toString()}`).then((response) =>
    response.json(),
  )) as HotelImage[]
}

export function trackUserAction(
  domain: Domain,
  trackingData:
    | TrackPageViewParams
    | TrackLikeParams
    | TrackHotelPageViewParams
    | TrackHotelImpressionLandingPageParams
    | TrackHotelImpressionSearchParams,
): void {
  const testVariantsCookieValue = getTestVariantsCookieValue(Cookies.get(TEST_VARIANTS_COOKIE))
  const testVariants = getTestVariantsString(testVariantsCookieValue, true)

  if (window.location.hostname === 'localhost') {
    return
  }

  // const testVariantsCookieValue = getTestVariantsCookieValue(Cookies.get(TEST_VARIANTS_COOKIE))
  // const testVariants = getTestVariantsString(testVariantsCookieValue, true)

  fetch(`${API_URL}tracking/`, {
    method: 'POST',
    body: JSON.stringify({
      domain: domain,
      ...trackingData,
      user: Cookies.get('user'),
      testVariants: testVariants,
    }),
  }).catch((e) => {
    console.debug(e)
  })
}

export const SEARCH_FALLBACK_RESULT = {
  SearchResult: {
    SearchResultItems: [],
    MapItems: [],
    NumberOfOffers: 0,
    NumberOfResults: 0,
  },
  BackfillResult: {
    SearchResultItems: [],
    MapItems: [],
    NumberOfOffers: 0,
    NumberOfResults: 0,
  },
  Currency: Currency.EUR,
  Message: '',
  NumberOfOffers: 0,
  NumberOfResults: 0,
}
