import React, { ReactElement } from 'react'
import classNames from 'classnames'

interface LoadingIndicatorProps {
  additionalClassName?: string
  white?: boolean
}

export default function LoadingIndicator(props: LoadingIndicatorProps): ReactElement {
  return (
    <div
      className={classNames(
        'lds-ellipsis h-[80px] w-[80px]',
        props.additionalClassName,
        {"lds-ellipsis--dark": !props.white}
      )}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
