import classNames from 'classnames'

export const BUTTON_STYLES_BG_PRIMARY_LIGHTENED = 'bg-primary-lightened text-stone-600 mr-2'
export const BUTTON_STYLES_BG_PRIMARY = 'bg-primary text-white'

export const WHITE_BUTTON_STYLES = 'bg-primary text-white'
export const BASE_BUTTON_STYLES = 'font-semibold rounded-md'
export const STANDARD_BUTTON_TEXT_SIZE = 'text-md md:text-sm'
export const STANDARD_BUTTON_STYLES = classNames(BASE_BUTTON_STYLES, WHITE_BUTTON_STYLES)
export const LARGE_BUTTON_STYLES = classNames(
  'font-semibold py-4 px-4 md:px-6 text-md md:text-xl rounded-md',
  WHITE_BUTTON_STYLES,
)

export const LINK_STRETCH_STYLES =
  "after:absolute after:inset-0 after:z-40 after:pointer-events-auto after:content-['']"

export const LINK_STYLES = 'underline'
